window.__tnt || (window.__tnt = {});
window.__tnt.subscription || (__tnt.subscription = {});
window.__tnt.subscription.offers || (__tnt.subscription.offers = {});

/******************************************************************************
 * Define variables for logging
 */
/* Track the checkout steps that have been viewed / completed */
__tnt.subscription.offers.loggedCheckoutStepsViewed = [];
__tnt.subscription.offers.loggedCheckoutStepsCompleted = [];
__tnt.subscription.offers.loggedPaymentInfoEntered = [];

/* Stores a unique ID to pass in checkout steps */
__tnt.subscription.offers.checkoutId = "";

/* Keep track of and update location inputs */
__tnt.subscription.offers.locationInputLengths = {
    address: 0,
    municipality: 0,
    region: 0,
    postal_code: 0,
    country: 0,
    phone: 0
};

/* Keep track of and update credit card inputs */
__tnt.subscription.offers.ccNameLength = 0;
__tnt.subscription.offers.ccValidNumber = false;
__tnt.subscription.offers.ccValidCvv = false;

/******************************************************************************
 * Initialize Events 
 */

/*
 * Product List Viewed
 * 
 * @event #BLOXSubscriptionProductListViewed
 */
logProductListViewed = new CustomEvent('BLOXSubscriptionProductListViewed', { detail: {} });

/*
 * Product Clicked
 * 
 * @event #BLOXSubscriptionProductClicked
 */
logProductClicked = new CustomEvent('BLOXSubscriptionProductClicked', { detail: {} });

/*
 * Product Viewed Details
 * 
 * @event #BLOXSubscriptionProductViewed
 */
logProductViewed = new CustomEvent('BLOXSubscriptionProductViewed', { detail: {} });

/*
 * Product Added to Cart
 * 
 * @event #BLOXSubscriptionProductAdded
 */
logProductAdded = new CustomEvent('BLOXSubscriptionProductAdded', { detail: {} });

/*
 * Product Removed from Cart
 * 
 * @event #BLOXSubscriptionProductRemoved
 */
logProductRemoved = new CustomEvent('BLOXSubscriptionProductRemoved', { detail: {} });

/*
 * Checkout Started
 * 
 * @event #BLOXSubscriptionCheckoutStarted
 */
logCheckoutStarted = new CustomEvent('BLOXSubscriptionCheckoutStarted', { detail: {} });

/*
 * Checkout Step Viewed
 * 
 * @event #BLOXSubscriptionCheckoutStepViewed
 */
logCheckoutStepViewed = new CustomEvent('BLOXSubscriptionCheckoutStepViewed', { detail: {} });

/*
 * Checkout Step Completed
 * 
 * @event #BLOXSubscriptionCheckoutStepCompleted
 */
logCheckoutStepCompleted = new CustomEvent('BLOXSubscriptionCheckoutStepCompleted', { detail: {} });

/*
 * Payment Info Entered
 * 
 * @event #BLOXSubscriptionPaymentInfoEntered
 */
logPaymentInfoEntered = new CustomEvent('BLOXSubscriptionPaymentInfoEntered', { detail: {} });


/*
 * Order Completed
 *
 * @event #BLOXSubscriptionOrderCompleted
 */
logOrderCompleted = new CustomEvent('BLOXSubscriptionOrderCompleted', { detail: {} });



/******************************************************************************
 * Helper Functions 
 */


/**
 * getPurchaseStep
 *
 * @param {string} stepName
 * @return {integer} The step number associated with that step
 */
{
    let purchaseStep = 0;
    let purchaseSteps = [];
    __tnt.subscription.offers.getPurchaseStep = function(stepName) {
        if (purchaseSteps[stepName]) {
            return purchaseSteps[stepName];
        } else {
            purchaseStep += 1;
            purchaseSteps[stepName] = purchaseStep;
            return purchaseSteps[stepName];
        }
    };
}


/*
 * setProduct
 * 
 * @returns object
 */
__tnt.subscription.offers.setProduct = function(data){
    try{
        if (data && data.product_id) {
            data.product_id = data.product_id.toString(); // Make sure product_id is a string

            __tnt.subscription.offers.productData = data;

            sessionStorage.setItem('__tnt.subscription.offers.productData', JSON.stringify(data));

            return data;
        } else { throw new Error("No product data found") }
    } catch(e){ return e }
};

/*
 * getProduct
 *
 * @returns object
 */
__tnt.subscription.offers.getProduct = function(){
    if(__tnt.subscription.offers.productData){
        return __tnt.subscription.offers.productData;
    } else if(sessionStorage.getItem('__tnt.subscription.offers.productData')){
        __tnt.subscription.offers.productData = JSON.parse(sessionStorage.getItem('__tnt.subscription.offers.productData'));
        
        return __tnt.subscription.offers.productData;
    }
};

/*
 * rmProduct
 */
__tnt.subscription.offers.rmProduct = function(){
    __tnt.subscription.offers.productData = null;
    
    try{
        sessionStorage.removeItem('__tnt.subscription.offers.productData')
    } catch(e){ }
    
    return false;
};

/*
 * getCheckoutId
 * Gets a unique ID to pass in checkout steps
 * This should be set when the user starts checkout
 * It should be cleared when the product is removed
 *
 * @returns string
 */
__tnt.subscription.offers.getCheckoutId = function(){
    var thisCheckoutId = "";

    if (__tnt.subscription.offers.checkoutId) {
        thisCheckoutId = __tnt.subscription.offers.checkoutId;
    } else if (sessionStorage.getItem('__tnt.subscription.offers.checkoutId')) {
        thisCheckoutId = sessionStorage.getItem('__tnt.subscription.offers.checkoutId');
    } else if (crypto) {
        thisCheckoutId = crypto.randomUUID();
    } else { 
        thisCheckoutId = String( Date.now().toString(32) + Math.random().toString(16) ).replace(/\./g, '');
    }

    __tnt.subscription.offers.checkoutId = thisCheckoutId;
    
    try {
        sessionStorage.setItem('__tnt.subscription.offers.checkoutId', thisCheckoutId);
    } catch(e){ }

    return thisCheckoutId;
};

__tnt.subscription.offers.clearCheckoutId = function(){
    try{
        __tnt.subscription.offers.checkoutId = "";
        sessionStorage.removeItem('__tnt.subscription.offers.checkoutId');
    } catch(e){ }
};


/*
 * getCartId
 * Gets a unique ID to pass in add and remove events
 * This should be set when the user adds a product
 * It should be cleared when the user completes an order or leaves the page
 *
 * @returns string
 */
__tnt.subscription.offers.getCartId = function() {
    var thisCartId = '';
    
    if (__tnt.subscription.offers.cartId) {
        thisCartId = __tnt.subscription.offers.cartId;
    } else if (crypto) {
        thisCartId = crypto.randomUUID();
    } else {
        thisCartId = String( Date.now().toString(32) + Math.random().toString(16) ).replace(/\./g, '');
    }
    
    __tnt.subscription.offers.cartId = thisCartId;
    
    return thisCartId;
};


/*
 * locationInputsEntered
 * Helper function for checking that all location inputs are filled out
 * so we can log the checkout step completed event for location at the correct time.
 *
 * @returns boolean
 */
__tnt.subscription.offers.locationInputsEntered = function() {
    if (
        __tnt.subscription.offers.locationInputLengths.address > 0
        && __tnt.subscription.offers.locationInputLengths.municipality > 0
        && __tnt.subscription.offers.locationInputLengths.region > 0
        && __tnt.subscription.offers.locationInputLengths.postal_code > 0
        && __tnt.subscription.offers.locationInputLengths.country > 0
        && __tnt.subscription.offers.locationInputLengths.phone > 0
    ) {
        return true;
    }
    return false;
};


/*
 * ccInputsEntered
 * Helper function for checking that all credit card inputs are filled out
 * so we can log the payment info entered event at the correct time.
 *
 * @returns boolean
 */
__tnt.subscription.offers.ccInputsEntered = function() {
    if (
        __tnt.subscription.offers.ccNameLength > 0 &&
        __tnt.subscription.offers.ccValidNumber &&
        __tnt.subscription.offers.ccValidCvv
    ) {
        return true;
    }
    return false;
};


/******************************************************************************
 *Event Emitting Functions 
 */

/*
 * logImpression
 *
 * @fires #BLOXSubscriptionProductListViewed
 */
__tnt.subscription.offers.logImpression = function(data, detail){
    var impressionsData = [],
        productData = [],
        offerData = [],
        offerGroup = null,
        siteNameEl = document.head.querySelector('meta[property="og:site_name"]'),
        siteName = "",
        ruleID = null,
        ruleName = null;

    if(data){
        offerGroup = data[0].offerGroupId;
        data.forEach(function(item){
            var impression = item.impressionData, 
                offer = item.offerData,
                image = "";
        
            if(item.impressionData){ impressionsData.push(impression) }
            if(item.offerData){
                if(item.offerData.image!= null){ image = offer.image }
                offerData.push(item.offerData);
            }
            if (item.productData) {
                if (item.productData.product_id) {
                    item.productData.product_id = item.productData.product_id.toString(); // Make sure product_id is a string
                }
                productData.push(item.productData);
            }
        });

        if(detail){
            if(detail.rule_id){ ruleID = detail.rule_id }
            if(detail.rule_name){ ruleName = detail.rule_name }
        }

        logProductListViewed.detail.impression = impressionsData;
        logProductListViewed.detail.offer = offerData;

        logProductListViewed.detail.action = "productListViewed";
        logProductListViewed.detail.ecommerce_channel = 'subscription';
        logProductListViewed.detail.list_id = offerGroup;
        logProductListViewed.detail.category = data[0].offerGroupName;
        logProductListViewed.detail.reason = data[0].offerContext;
        logProductListViewed.detail.paywall_display = data[0].paywallDisplay;

        logProductListViewed.detail.access_rule_name = ruleName;
        logProductListViewed.detail.access_rule_id = ruleID;

        logProductListViewed.detail.products = productData;

        window.dispatchEvent(logProductListViewed)
    }
};

/*
 * logClick
 *
 * @fires #BLOXSubscriptionProductClicked
 */
__tnt.subscription.offers.logClick = function(productData){
    if(productData){
        __tnt.subscription.offers.setProduct(productData);
        logProductClicked.detail.action = "productClicked";
        logProductClicked.detail.ecommerce_channel = 'subscription';
        logProductClicked.detail.product = productData;

        window.dispatchEvent(logProductClicked);
    }
}

/*
 * logDetails
 *
 * @fires #BLOXSubscriptionProductViewed
 */
__tnt.subscription.offers.logDetails = function(){
    logProductViewed.detail.action = "productViewed";
    logProductViewed.detail.ecommerce_channel = 'subscription';

    var product = __tnt.subscription.offers.getProduct();

    if(product){
        logProductViewed.detail.product = product;

        window.dispatchEvent(logProductViewed);
    }
}

/*
 * logProductAdded
 *
 * @fires #BLOXSubscriptionProductAdded
 */
__tnt.subscription.offers.logProductAdded = function(){
    var product = __tnt.subscription.offers.getProduct();

    if(product){
        logProductAdded.detail.action = "productAdded";
        logProductAdded.detail.ecommerce_channel = 'subscription';
        logProductAdded.detail.cart_id = __tnt.subscription.offers.getCartId();
        logProductAdded.detail.product = product;

        window.dispatchEvent(logProductAdded);
        if(__tnt.subscription.offers.checkoutId == ""){
            __tnt.subscription.offers.logCheckoutStarted();
        }
    }
}

/*
 * logProductRemoved
 *
 * @fires #BLOXSubscriptionProductRemoved
 */
__tnt.subscription.offers.logProductRemoved = function(){
    var product = __tnt.subscription.offers.getProduct();

    if(product){
        logProductRemoved.detail.action = "productRemoved";
        logProductRemoved.detail.ecommerce_channel = 'subscription';
        logProductRemoved.detail.cart_id = __tnt.subscription.offers.getCartId();
        logProductRemoved.detail.product = product;

        // clear the checkout id since this starts a new checkout session
        __tnt.subscription.offers.checkoutId = "";

        window.dispatchEvent(logProductRemoved);
    }
}

/* 
 * logCheckoutStarted
 *
 * @fires #BLOXSubscriptionCheckoutStarted
 */
__tnt.subscription.offers.logCheckoutStarted = function(){
    var product = __tnt.subscription.offers.getProduct();

    if(product){
        // Starting new checkout, clearing checkout ID
        __tnt.subscription.offers.checkoutId = "";

        // Clear checkout steps
        __tnt.subscription.offers.loggedCheckoutStepsViewed = [];
        __tnt.subscription.offers.loggedCheckoutStepsCompleted = [];

        // Clear checkout ID if already set
        __tnt.subscription.offers.clearCheckoutId();

        logCheckoutStarted.detail.action = "checkoutStarted";
        logCheckoutStarted.detail.ecommerce_channel = 'subscription';
        logCheckoutStarted.detail.checkout_id = __tnt.subscription.offers.getCheckoutId();
        logCheckoutStarted.detail.value = product.price;
        logCheckoutStarted.detail.revenue = product.price;
        logCheckoutStarted.detail.shipping = 0;
        logCheckoutStarted.detail.tax = 0;
        logCheckoutStarted.detail.coupon = "";
        logCheckoutStarted.detail.currency = product.currency;

        logCheckoutStarted.detail.products = [];
        logCheckoutStarted.detail.products.push(product);

        window.dispatchEvent(logCheckoutStarted);
    }
}

/*
 * logCheckoutStep
 *
 * @fires #BLOXSubscriptionCheckoutStepViewed
 */
__tnt.subscription.offers.logCheckoutStepViewed = function(options){
    var product = __tnt.subscription.offers.getProduct();

    if (!options.step) options.step = 1;
    if (!options.label) options.label = 'Entering email address';
    
    if(product && (options.label == 'Selected payment method' || !__tnt.subscription.offers.loggedCheckoutStepsViewed.includes(options.step))){
        logCheckoutStepViewed.detail.action = "checkoutStepViewed";
        logCheckoutStepViewed.detail.ecommerce_channel = 'subscription';
        logCheckoutStepViewed.detail.checkout_id = __tnt.subscription.offers.getCheckoutId();
        logCheckoutStepViewed.detail.step = options.step;
        logCheckoutStepViewed.detail.label = options.label;
        if (options.paymentMethod) logCheckoutStepViewed.detail.payment_method = options.paymentMethod;

        logCheckoutStepViewed.detail.products = [];
        logCheckoutStepViewed.detail.products.push(product);

        window.dispatchEvent(logCheckoutStepViewed);

        __tnt.subscription.offers.loggedCheckoutStepsViewed.push(options.step);
    }
}
 
/*
 * logCheckoutStepCompleted
 *
 * @fires #BLOXSubscriptionCheckoutStepCompleted
 */
__tnt.subscription.offers.logCheckoutStepCompleted = function(options){
    var product = __tnt.subscription.offers.getProduct();

    if (!options.step) options.step = 1;
    if (!options.label) options.label = 'Email address defined';

    if(product && (options.label == 'Selected payment method' || !__tnt.subscription.offers.loggedCheckoutStepsCompleted.includes(options.step))){

        logCheckoutStepCompleted.detail.action = "checkoutStepCompleted";
        logCheckoutStepCompleted.detail.ecommerce_channel = 'subscription';
        logCheckoutStepCompleted.detail.checkout_id = __tnt.subscription.offers.getCheckoutId();
        logCheckoutStepCompleted.detail.step = options.step;
        logCheckoutStepCompleted.detail.label = options.label;
        if (options.paymentMethod) logCheckoutStepCompleted.detail.payment_method = options.paymentMethod;

        logCheckoutStepCompleted.detail.products = [];
        logCheckoutStepCompleted.detail.products.push(product);

        window.dispatchEvent(logCheckoutStepCompleted);

        __tnt.subscription.offers.loggedCheckoutStepsCompleted.push(options.step);
    }
}

/*
 * logPaymentInfoEntered
 *
 * @fires #BLOXSubscriptionPaymentInfoEntered
 */
__tnt.subscription.offers.logPaymentInfoEntered = function(options){
    if (__tnt.subscription.offers.loggedPaymentInfoEntered[options.paymentMethod]) return;

    if (!options.step) options.step = 3;

    var product = __tnt.subscription.offers.getProduct();

    if(product){
        logPaymentInfoEntered.detail.action = "paymentInfoEntered";
        logPaymentInfoEntered.detail.ecommerce_channel = 'subscription';
        logPaymentInfoEntered.detail.checkout_id = __tnt.subscription.offers.getCheckoutId();
        logPaymentInfoEntered.detail.step = options.step;
        if (options.paymentMethod) logPaymentInfoEntered.detail.payment_method = options.paymentMethod;

        logPaymentInfoEntered.detail.products = [];
        logPaymentInfoEntered.detail.products.push(product);

        window.dispatchEvent(logPaymentInfoEntered);

        __tnt.subscription.offers.loggedPaymentInfoEntered[options.paymentMethod] = true;
    }
}

/*
 * logOrderCompleted
 *
 * @fires #BLOXSubscriptionOrderCompleted
 */
__tnt.subscription.offers.logOrderCompleted = function(transactionId){
    var product = __tnt.subscription.offers.getProduct();

    if(product){
        logOrderCompleted.detail.action = "orderCompleted";
        logOrderCompleted.detail.ecommerce_channel = 'subscription';
        logOrderCompleted.detail.checkout_id = __tnt.subscription.offers.getCheckoutId();
        logOrderCompleted.detail.order_id = transactionId ? transactionId.toString() : undefined; // transaction id returned by software converted to a string
        logOrderCompleted.detail.affiliation = product.brand; // I think this is supposed to be the site's brand name?
        logOrderCompleted.detail.subtotal = product.price;
        logOrderCompleted.detail.revenue = product.price; // also price
        logOrderCompleted.detail.tax = parseFloat(sessionStorage.getItem('__tnt.service.rate.tax')) || 0;

        logOrderCompleted.detail.discount = ""; // will need to add this when we add coupon support
        logOrderCompleted.detail.currency = product.currency; // going to come back from the offer, hard code for now

        logOrderCompleted.detail.products = [];
        logOrderCompleted.detail.products.push(product);

        window.dispatchEvent(logOrderCompleted);

        // now that we're done, clear the stored product, checkout id, and step variables
        __tnt.subscription.offers.rmProduct();
        __tnt.subscription.offers.clearCheckoutId();
        __tnt.subscription.offers.cartId = '';

        __tnt.subscription.offers.loggedCheckoutStepsViewed = [];
        __tnt.subscription.offers.loggedCheckoutStepsCompleted = [];
    }
}
